<template>
  <div class="Tags">
    <transition name="fade" mode="out-in">
      <router-view class="panel" />
    </transition>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/tags/Tags.scss';
</style>
